import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Typography,
  Box,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { ORG_FETCH_PARTICULAR, ORG_UPDATERATING } from '../../api/org';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const OrgBookings = () => {
  const { id } = useParams();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false); // For view-only dialog
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [formData, setFormData] = useState({});
  const [screenshot, setScreenshot] = useState(null);
  const [existingData, setExistingData] = useState(null);  // To track existing data from the backend
  const [file , setFile] = useState(null);
  const [open , setOpen] = useState(false);

  // Fetch bookings on component mount
  useEffect(() => {
    axios
      .get(ORG_FETCH_PARTICULAR + id)
      .then((response) => {
        const sortedBookings = response.data.data.sort((a, b) => {
          const dateTimeA = dayjs(`${a.bookingDate} ${a.time}`, 'YYYY-MM-DD h:mm A');
          const dateTimeB = dayjs(`${b.bookingDate} ${b.time}`, 'YYYY-MM-DD h:mm A');
          return dateTimeA.diff(dateTimeB);
        });
        setBookings(sortedBookings);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching bookings:', error);
        setLoading(false);
      });
  }, [id]);

  // Load and save form data to localStorage
  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('formData')) || {};
    setFormData(savedData);
  }, []);

  useEffect(() => {
    localStorage.setItem('formData', JSON.stringify(formData));
  }, [formData]);

  const handleOpenDialog = (bookingId) => {
    const booking = bookings.find((b) => b._id === bookingId);
    setCurrentBookingId(bookingId);

    // Check if the booking already has rating or feedback data
    if (booking?.ratingOverall || booking?.feedback) {
      setExistingData(booking);  // Existing data available
      setViewDialogOpen(true);  // Open view dialog
    } else {
      setExistingData(null);  // No existing data, can update
      setOpenDialog(true);  // Open edit dialog
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setViewDialogOpen(false);
    setCurrentBookingId(null);
    setExistingData(null);
  };

  const handleInputChange = (name, value) => {
    if (!existingData) {
      setFormData((prev) => ({
        ...prev,
        [currentBookingId]: {
          ...prev[currentBookingId],
          [name]: value,
        },
      }));
    }
  };

  const handleScreenshotUpload = (file) => {
    const reader = new FileReader();
    setFile(file);
    reader.onload = () => setScreenshot(reader.result);
    reader.readAsDataURL(file);
    handleInputChange('screenshot', reader.result);
  };

  const handlePasteScreenshot = (e) => {
    const clipboardData = e.clipboardData;
    const file = clipboardData.files[0];
    setFile(clipboardData.files[0]);
    if (file && file.type.startsWith('image/')) {
      handleScreenshotUpload(file);
    }
  };

  const handleSubmit = () => {
    const dataToSubmit = formData[currentBookingId] || {};
    const formData1 = new FormData();
  
    // Append the data fields to FormData
    for (const [key, value] of Object.entries(dataToSubmit)) {
      formData1.append(key, value);
    }
  
    // Append the file (screenshot) if available
    if (screenshot) {
      formData1.append('screenshot', file);
    }
  
    axios
      .put(`${ORG_UPDATERATING}${currentBookingId}/rating`, formData1, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        setBookings((prev) =>
          prev.map((booking) =>
            booking._id === currentBookingId
              ? { ...booking, ...response.data }
              : booking
          )
        );
        handleCloseDialog();
        setFormData((prev) => {
          const newFormData = { ...prev };
          delete newFormData[currentBookingId];
          return newFormData;
        });
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };
  

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'whatsappNumber', headerName: 'WhatsApp', width: 100 },
    { field: 'studentCode', headerName: 'Student Code', width: 80 },
    {
      field: 'bookingDate',
      headerName: 'Booking Date',
      width: 150,
      valueGetter: (params) =>
        params.row.bookingDate
          ? dayjs(params.row.bookingDate).format('DD MMM YYYY')
          : 'Not Booked',
    },
    { field: 'time', headerName: 'Booking Time', width: 100 },
    {
      field: 'form',
      headerName: 'Form',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog(params.row._id)}
        >
          {params.row.ratingOverall ? 'View Ratings' :'Open Form'}
        </Button>
      ),
    },
  ];

  return (
    <Container sx={{ width: '100%' }} onPaste={handlePasteScreenshot}>
      <Box sx={{ mt: 4, width: '100%' }}>
        <Typography variant="h4" gutterBottom>
          Organization Bookings
        </Typography>
        <Box sx={{ height: '90vh', width: '100%' }}>
          <DataGrid
            rows={bookings}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row._id}
            loading={loading}
          />
        </Box>
      </Box>

      {/* Edit Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Submit Rating and Review</DialogTitle>
  <DialogContent>
    {/* Ratings */}
    {[
      { name: 'ratingOverall', label: 'Rating Overall' },
      { name: 'communicationRating', label: 'Communication Rating' },
      { name: 'technicalSkillsetRating', label: 'Technical Skillset Rating' },
    ].map((ratingField) => (
      <Box key={ratingField.name} mt={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{ratingField.label}</FormLabel>
          <RadioGroup
            row
            value={formData[currentBookingId]?.[ratingField.name] || ''}
            onChange={(e) => handleInputChange(ratingField.name, e.target.value)}
          >
            {[...Array(11).keys()].map((value) => (
              <FormControlLabel
                key={value}
                value={value.toString()}
                control={<Radio />}
                label={value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    ))}

    {/* Feedback */}
    <Box mt={3}>
      <Typography>Feedback:</Typography>
      <ReactQuill
        value={formData[currentBookingId]?.feedback || ''}
        onChange={(value) => handleInputChange('feedback', value)}
        placeholder="Write your feedback..."
      />
    </Box>

    {/* SWOT Analysis */}
    {['Strength', 'Weakness', 'Threat', 'Opportunity'].map((field) => (
      <Box key={field} mt={3}>
        <Typography>{field}:</Typography>
        <ReactQuill
          value={formData[currentBookingId]?.[field.toLowerCase()] || ''}
          onChange={(value) => handleInputChange(field.toLowerCase(), value)}
          placeholder={`Enter ${field.toLowerCase()}...`}
        />
      </Box>
    ))}

    {/* Screenshot Upload */}
    <Box
      sx={{
        mt: 2,
        p: 2,
        border: '2px dashed #ccc',
        borderRadius: '8px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#f9f9f9',
      }}
      onDrop={(e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) handleScreenshotUpload(file);
      }}
      onDragOver={(e) => e.preventDefault()}
    >
      {screenshot ? (
        <img
          src={screenshot}
          alt="Screenshot Preview"
          style={{ maxWidth: '100%', maxHeight: 200 }}
        />
      ) : (
        <Typography>
          Paste, Drag & Drop, or Click to Upload Screenshot
        </Typography>
      )}
    </Box>

    <Button variant="contained" component="label" fullWidth sx={{ mt: 2 }}>
      Upload Screenshot
      <input
        type="file"
        hidden
        onChange={(e) => handleScreenshotUpload(e.target.files[0])}
      />
    </Button>
  </DialogContent>

  <DialogActions>
    <Button onClick={handleCloseDialog} color="secondary">
      Cancel
    </Button>
    <Button
      onClick={handleSubmit}
      color="primary"
      variant="contained"
      startIcon={<SaveIcon />}
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>


      {/* View-Only Dialog */}
      <Dialog open={viewDialogOpen} onClose={handleCloseDialog}>
  <DialogTitle>View Rating and Review</DialogTitle>
  <DialogContent>
    {existingData && (
      <>
        {[{ name: 'ratingOverall', label: 'Rating Overall' },
          { name: 'communicationRating', label: 'Communication Rating' },
          { name: 'technicalSkillsetRating', label: 'Technical Skillset Rating' },
        ].map((ratingField) => (
          <Box key={ratingField.name} mt={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">{ratingField.label}</FormLabel>
              <RadioGroup
                row
                value={existingData[ratingField.name] || ''}
                onChange={(e) => handleInputChange(ratingField.name, e.target.value)}

              >
                {[...Array(11).keys()].map((value) => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={<Radio />}
                    label={value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        ))}

        <Box mt={2}>
          <Typography>Feedback:</Typography>
          <ReactQuill
            value={existingData.feedback || ''}
            onChange={(value) => handleInputChange('feedback', value)}

          />
        </Box>

        {['Strength', 'Weakness', 'Threat', 'Opportunity'].map((field) => (
          <Box key={field} mt={2}>
            <Typography>{field}:</Typography>
            <ReactQuill
              value={existingData[field.toLowerCase()] || ''}
            onChange={(value) => handleInputChange(field.toLowerCase(), value)}
            />
          </Box>
        ))}
        <Box
          component={'img'}
          src={existingData.screenshot}
          sx={{
            width: 300,
            height: 300,
            marginTop: 10
          }}
        />
      </>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="secondary">
      Close
    </Button>
    <Button
      onClick={handleSubmit}
      color="primary"
      variant="contained"
      startIcon={<SaveIcon />}
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>
    </Container>
  );
};

export default OrgBookings;
